import React from "react";
import ReadMoreReact from "read-more-react";
import { Zoom } from "react-reveal";
import {
  FacebookShareCount,
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

const BlogContainer = (props) => {
  return (
    <div
      id={props.id}
      style={{
        background: "#ffffff",
        marginTop: "30px",
        marginBottom: "30px",
        paddingTop: "20px",
      }}
    >
      <div className="container">
        <Zoom>
          <h3 style={{ fontWeight: "800", fontSize: "26px" }}>{props.title}</h3>
          <p style={{ fontWeight: "lighter" }}>
            <b>Article By: {props.poster}</b>
            <br />
            <span style={{ color: "olive" }}>{props.posted_date}</span>
          </p>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <img src={props.image} className="img-responsive" alt="" />
              <p>share article</p>
              <section style={{ display: "flex" }}>
                <div className="Demo__some-network">
                  <TwitterShareButton
                    url={
                      "https://www.orchiddigitalconsulting.com/articles#" +
                      props.id
                    }
                    title={props.title + " by " + props.poster}
                    className="Demo__some-network__share-button"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>

                  <div className="Demo__some-network__share-count">&nbsp;</div>
                </div>

                {/* ///start */}

                <div className="Demo__some-network">
                  <FacebookShareButton
                    url={
                      "https://www.orchiddigitalconsulting.com/articles#" +
                      props.id
                    }
                    quote={props.title + " by " + props.poster}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <div>
                    <FacebookShareCount
                      url={
                        "https://www.orchiddigitalconsulting.com/articles#" +
                        props.id
                      }
                      className="Demo__some-network__share-count"
                    >
                      {(count) => count}
                    </FacebookShareCount>
                  </div>
                </div>

                {/* {///third/} */}

                <div className="Demo__some-network">
                  <TelegramShareButton
                    url={
                      "https://www.orchiddigitalconsulting.com/articles#" +
                      props.id
                    }
                    title={props.title + " by " + props.poster}
                    className="Demo__some-network__share-button"
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>

                  <div className="Demo__some-network__share-count">&nbsp;</div>
                </div>

                <div className="Demo__some-network">
                  <WhatsappShareButton
                    url={
                      "https://www.orchiddigitalconsulting.com/articles#" +
                      props.id
                    }
                    title={props.title + " by " + props.poster}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>

                  <div className="Demo__some-network__share-count">&nbsp;</div>
                </div>

                <div className="Demo__some-network">
                  <LinkedinShareButton
                    url={
                      "https://www.orchiddigitalconsulting.com/articles#" +
                      props.id
                    }
                    className="Demo__some-network__share-button"
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
              </section>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <p style={{ fontWeight: "400" }}>
                  <ReadMoreReact
                    title
                    min={300}
                    ideal={500}
                    max={1300}
                    text={props.post}
                    readMoreText={<button>read more</button>}
                  />
                </p>

                <br />
              </div>
            </div>
          </div>
        </Zoom>
      </div>
    </div>
  );
};

export default BlogContainer;
