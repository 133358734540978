import React from 'react'
import { Link } from 'react-router-dom'

export default function NavBarsm() {
    return (
        <div  className="text-center modal  fade mt-5 pt-5 bg-green" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div style={{marginTop:'13vh'}}   className="modal-dialog bg-blue text-center" role="document">
    <div className="modal-content bg-blue" style={{margin:"auto", maxWidth:"300px"}}>
  
        
        <div  type="button" className="text-center" data-dismiss="modal" aria-label="Close">
          
          <span aria-hidden="true"  className="iconify" data-inline="false" data-icon="ion:close-circle" style={{color: "green",width:"60px", height:"60px", marginTop:"-60px"}}></span>
        
      </div>
      <div className="modal-body" style={{marginTop:"-30px", margin:"auto" }}>
      <ul className= "text-center h5 pt-5" style={{color:"black", height:"300px"}}>
      <li><a href="/" style={{color:"black", fontWeight:"lighter"}}>Home</a></li>
      <hr/>
     
      <li> <a href="/services" style={{color:"black", fontWeight:"lighter"}}>Services</a></li>
      <hr/>
  
      <li> <a href="/about" style={{color:"black", fontWeight:"lighter"}}>About</a></li>
      <hr/>
      <li className="nav-item dropdown">
      <Link to="/#" className="nav-link dropdown-toggle" style={{color:"black", fontWeight:"lighter"}}  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         Contact
        
        </Link>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
         
          <a href = "/contactus" className="page-scroll dropdown-item" style={{color:"black", fontSize:"14", fontWeight:"light"}}>
                 Contact Us
                </a>
                <hr/>
                <a href= "/consultus" className="page-scroll dropdown-item" style={{color:"black",fontSize:"14",fontWeight:"light"}}>
                 Book A Consultation
                </a>
        </div>
      </li>
      <hr/>
      <li className="nav-item dropdown">
      <Link to="/#" className="nav-link dropdown-toggle" style={{color:"black", fontWeight:"lighter"}}  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         Others
        
        </Link>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown1">
         
          <a href = "/articles" className="page-scroll dropdown-item" style={{color:"black", fontSize:"14", fontWeight:"light"}}>
                 Articles
                </a>
                <hr/>
                <a href= "/faqs" className="page-scroll dropdown-item" style={{color:"black",fontSize:"14",fontWeight:"light"}}>
                 FAQS
                </a>
        </div>
      </li>
      <hr/>
      
      <li>
        <a href="/apply" ><button className="btn btn-custom btn-lg text-dark p-2" style={{background:"white", color:"blue !important", border:"1px solid blue", margin:"5px"}} >Enroll Today</button></a>

              </li>

 
     
      </ul>
      </div>
      
    </div>
  </div>
</div>
    )
}
