import React from 'react'
import "./vt.css"
export default function vt() {
    return (
        <div>
<section  class="testimonial">
 <div class="container-fluid">
 <div class="row">
 
 
 {/* <div class="testimonial-heading">
 <h2>Video Testimonials</h2>
 </div> */}
 <p> </p>
 
 
  <div class="container">
  <div className="section-title text-center" style={{fontWeight:900}}>
            <h2>Watch Video Testimonials</h2>
          </div>
  <div class="row" >
    <div class="col-md-12">
      
		
  
  <div class="row">
    <div class="col-md-12">
      <div class="carousel slide media-carousel" id="abc">
        <div class="carousel-inner">
        

          <div class="item  active">  
          <div class="col-md-12 col-sm-12 col-xs-12">
         
     		  <div class="panel panel-default">
               <div class="panel-header" style={{height:"30px"}}>
            <div class="panel-footer-txt">
            {/* <p>More Tetimonials coming soon...</p> */}
            
            </div>
            </div>
     		<div class="panel-body">
            <div class="embed-responsie embd-responsive-16by9">
            <iframe title="first" width="100%" height="315"  src="https://www.youtube.com/embed/4gv6KWKjRyw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
               </div>
           
         
          </div>
          
            <div class="panel-footer" >
            <div class="panel-footer-txt">
            <a style={{color:"white"}} href="https://m.youtube.com/channel/UCs-A6_ceAfUXl7M8OgPw-YQ" target="_blank" rel="noopener noreferrer" ><p>More Testimonials...</p> </a>
            
            </div>
            </div>
            </div>
            
         
          </div>
          
        </div>
          
          
         
          {/* <div class="item">  
          
           <div class="col-md-6 col-sm-12 col-xs-12">
         
        <div class="panel panel-default">
     		<div class="panel-body">
            <div class="embed-responsive embed-responsive-16by9">
  <iframe title="v1" width="560" height="315" src="https://www.youtube.com/embed/al2jmNLvmPI" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
			</div>
         
          </div>
          
            <div class="panel-footer">
            <div class="panel-footer-txt">
            <p>John Doe</p>
            <div style={{float:"right", marginTop: "-25px"}}>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            </div>
            </div>
            </div>
            </div>
            
          
          </div>
          
            <div class="col-md-6 col-sm-12 col-xs-12">
         
     			 <div class="panel panel-default">
     		<div class="panel-body">
            <div class="embed-responsive embed-responsive-16by9">
 <iframe title="v2" width="560" height="315" src="https://www.youtube.com/embed/fhRGUYd8ZRw" frameborder="0" gesture="media"  allowfullscreen></iframe>
			</div>
         
          </div>
          
            <div class="panel-footer">
            <div class="panel-footer-txt">
            <p>John Doe</p>
            <div style={{float:"right", marginTop: "-25px"}}>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            </div>
            </div>
            </div>
            </div>
         
          </div>
          
          
          </div> */}
          
        </div>
        {/* <a data-slide="prev" href="#abc" class="leftar carousel-control" ><img src="https://lh3.googleusercontent.com/DQV4Kju297pbLw-OxHyBU7Stw0iuaJj7zyyLoz8TQetixBNKPeCY-d5FJkzyA-xUztVRKdgOFIGyNsUSlGLMwskym6FRT6DKFFLN-_CptX_VlFZGmtsnK4xqZwTU9Yxvh2kCxwhatdqbzk_Uky3SGei4QVY-zopEgvKW_S5IC5oTcISYOIM1VjCnYcqJJDrw8RrXTEQj2UrWq0_EO0QMiujCzMH2ZxU68SJT1ioueqCI_pkr9v3PJPiFaeByAf7S3FDcbBzC3J-l5HXNtx70UnMSy_tySicFSPJnhVxsci0D9FE_nYugmc6ZEDhG0HsIN6WpHlxGEoBE8Tlhfo4-hQduEIF2n3yJO-gPBzlLpPkp8WuyWPzAFi64X01yOW6djYLSh3UoWje0pLm8FBUCtp5fjYTBn51iCc3ZP06RMdDaMM9abgQDVpM3GMRZqcYMAST02xq6cp1F3TCfqnUkyCjkZnTdED9Ioi5gIljrWhqgBOqcW9HKrBFHibGfZB109kl_SPJpxCOVlUQZ9tqIRsh1tlru-5G4ngtnXFd_cEq0SGdYxe2fSjSkQxFVbdu_VULBFGNSoL0zjVKmTaza48lTQUfRlq1k-_Zdj8Q=s256-no" class="img-responsive" alt="control"/>NEXT</a>
        <a data-slide="next" href="#abc" class="rightar carousel-control"><img src="https://lh3.googleusercontent.com/TgD9r34yTa_kofhI58_Rmhpnp87dqstYVcX9ygEROSyUlTcc8gBYrQUcJnIKu3yI4B5pXRd0Vecr2sxbxppP7vKHmYkQQ1YkxeTCqXiAkgxSrH-IgRdCHGdP21rwcZkfGb9dZaQ2sBN9ZEw0pf5hZfpS8GNJ4B9IpOOmMeqkQ22IhhvLAHEPwPieAsPUb1T3K8yi4jLbx-BZWtIMlczqft_Ya6oz_72ub2nkkRHr9USmLH_bjTwjjyca7CyFnxwACa3ANGmfTgNkFOrEZeU6zec0JvM2vvg-TqUDXmLGYGjY6-ZnKaaMi4kOl9d7lzyEC688wjZJ7XQzD9Y6dzttnSyiYX3nxUDAWDkRfDtxhZxV9U-uBYB2C72mPYAFEn8mIq-EZlUlQ28UD2epCQTcbdWlDy2hkMvE75ABzTVJu8DNttfPBY2lX1HzZJedpNJ6VPyYvAIRi5MrsKYb6WKDfaD0Mu8CpGE0fbF6p4qXpJCyk-zUdkJjvqJ8ZLHv-OXwyXkH4tTzsDqdJ9xJWopLcuNLjESwHo_iSVad-S7GgEXZWpAlDBHoy8NO3ikPCmi1t9PRlfdk8AgrZBp1g0YPPeM4mfC2MFwWJsiulFM=s256-no" alt="right arrow" class="img-responsive"/>PREV</a> */}
      </div>
    </div>
  </div>   
      
      
                                
    </div>
  </div>  
</div>  
 
 <p> </p>

 
 
 </div>
 </div>
 
 </section>
        </div>
    )
}
