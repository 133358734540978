import React, { useState, useContext } from "react";
import classess from "./reg.module.css"
import { useHistory } from "react-router-dom";
import UserContext from "../../context/userContext";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";
import bg from "../../../../Images/bg/bg.jpg"
const Endpoint="https://warm-wildwood-97173.herokuapp.com"||"http://localhost:500"

export default function Register() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordCheck, setPasswordCheck] = useState();
  const [displayName, setDisplayName] = useState();
  const [error, setError] = useState();

  const { setUserData } = useContext(UserContext);
  const history = useHistory();

  const submit = async (e) => {
    e.preventDefault();

    try {
      const newUser = { email, password, passwordCheck, displayName };
      await Axios.post(Endpoint+"/users/register", newUser);
      const loginRes = await Axios.post(Endpoint+"/users/login", {
        email,
        password,
      });
      setUserData({
        token: loginRes.data.token,
        user: loginRes.data.user,
      });
      localStorage.setItem("auth-token", loginRes.data.token);
      history.push("/");
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg);
    }
  };

  return (
    <div className="page" style= {{ height:"100vh", background:"#C72481",color:"white",backgroundImage: `url(${bg})`, }}>
   
   <h2 style={{width:"80%", margin:"auto", paddingTop:'10px'}}>Register</h2>
      <h2 style={{width:"80%", margin:"auto", paddingTop:'20px'}}>
      {error && (
        
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
     </h2>
       <div style={{width:"80%", margin:"auto", paddingTop:"20vh"}}>
      <form className="form" onSubmit={submit} style={{width:"80%"}}>
        <label
         style={{color:"green", fontSize:'20px'}}
        htmlFor="register-email">Email</label>
        <input
        style={{width:"100%"}}
        className={classess.input}
          id="register-email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />

        <label 
         style={{color:"green", fontSize:'20px'}}
        htmlFor="register-password">Password</label>
        <input
          style={{width:"100%", paddingTop:"20px"}}
        className={classess.input}
          id="register-password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          style={{width:"100%", paddingTop:"20px"}}
         className={classess.input}
          type="password"
          placeholder="Verify password"
          onChange={(e) => setPasswordCheck(e.target.value)}
        />

        <label
            style={{color:"green", fontSize:'20px'}}
             htmlFor="register-display-name">Display name</label>
        <input
         style={{width:"100%", paddingTop:"20px"}}
        className={classess.input}
          id="register-display-name"
          type="text"
          onChange={(e) => setDisplayName(e.target.value)}
        />

        <input
         style={{width:"100%"}}
          className="btn btn-lg btn-dark" type="submit" value="Register" />
      </form>
      </div>
    </div>
  );
}
