import styled from "styled-components"

export const StyledFaq = styled.div`
  cursor: pointer;
  margin: 0 0 10px;
  border:2px solid white;
  div.faq-question {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 800;
    margin: 0 0 5px;
  }
  div.faq-answer {
    margin-top: 20px;
    background: #fff;
    font-size: 14px;
    overflow: hidden;
    span {
      display: block;
      padding: 20px 10px;
    }
  }
`

export const StyledFaqsList = styled.div`
  background: #efefef;
 
  padding: 1rem;
`
