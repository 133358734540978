import React from "react";
import Background from "../../Images/bg/bg.jpg";
import Footer from "../Layout/Footer";
import NavBar from "../Layout/NavBar";
import NavBarsm from "../Layout/NavBarsm";
import FaqsList from "./FAQsList"


export default function index() {
  return (
    <div>
     
      <>
        <NavBarsm />
        <NavBar />
        <div
          style={{
            margin: "auto",
            backgroundImage: `url(${Background})`,
          
          }}
        >
          <div
          style={{
            minHeight:"60vh"
        ,
        margin:"auto",
        width:"80%"
          }}>
           
         

        <div className="panel-group" id="accordion" style={{paddingTop:"20vh"}}>
         <div style={{margin:"auto", fontSize:"30px"}}>FREQUENTLY ASKED QUESTIONS</div> 


{/* 
         <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
        <a data-toggle="collapse" data-parent="#accordion" href="#collapse8" style={{display:"grid", gridTemplateColumns:"90% 10%"}}>AGSMEIS excluding other Loan Applications etc commercial bank, private financial institutions and international and local grants?  <i className="fa fa-angle-double-down"></i></a>
      
        </h4>
      </div>
      <div id="collapse8" class="panel-collapse collapse in">
        <div class="panel-body">     AGSMEIS excluding other Loan Applications etc commercial bank, private financial institutions and international and local grants.</div>
      </div>
      </div> */}

{/* 
      
   

      <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
     
        <a data-toggle="collapse" data-parent="#accordion" href="#collapse10" style={{display:"grid", gridTemplateColumns:"90% 10%"}}>How can I access to international and local grant?  <i className="fa fa-angle-double-down"></i></a>
        </h4>
      </div>
      <div id="collapse10" class="panel-collapse collapse in">
        <div class="panel-body"> Apply through our website or contact the offices on telephone or in person.</div>
      </div>
      </div>

    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapse1" style={{display:"grid", gridTemplateColumns:"90% 10%"}}>What is the time duration to repay back the AGSMEIS loan? <i className="fa fa-angle-double-down"></i></a>
        </h4>
      </div>
      <div id="collapse1" class="panel-collapse collapse in">
        <div class="panel-body">It takes about 5years and one year moratorium of 5% per annum, after the first year moratorium its 9% per annum, it is a seven years repayment plan.
        <br/>
        1: Excluding other Loan Applications etc commercial bank, private financial institutions and international and local grants.
        <br/>
        2: while commercial bank require the following - C of O, - Business Plan, - 2 passport photo, -CAC documents etc, incase of asset financing include list of Asset for purchase.<br/>
        3: For commercial bank it takes about 2-3 weeks before disbursal from bank to obiligor account.<br/>
        4: For commercial bank as much as 500million - 1 billion Naira.<br/>
        5: Private financial Institution can also go for 10 million to 50million</div>
      </div>
    </div>


    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapse2" style={{display:"grid", gridTemplateColumns:"90% 10%"}}>Basic requirements to access the AGSMEIS loan? <i className="fa fa-angle-double-down"></i></a>
        </h4>
      </div>
      <div id="collapse2" class="panel-collapse collapse in">
        <div class="panel-body">Individuals must have a valid Nigeria I.D, 2 passport photograph, must have a registered business name. attend a one week entrepreneurship training with a certificate given at the end of the training.</div>
      </div>
    </div>


    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapse3" style={{display:"grid", gridTemplateColumns:"90% 10%"}}>  How long does it take to get the AGSMEIS loan?  <i className="fa fa-angle-double-down"></i></a>
        </h4>
      </div>
      <div id="collapse3" class="panel-collapse collapse in">
        <div class="panel-body">  Between 6-8weeks.</div>
      </div>
      </div>



      <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapse4" style={{display:"grid", gridTemplateColumns:"90% 10%"}}>  Which bank disburses the AGSMEIS loan?  <i className="fa fa-angle-double-down"></i></a>
        </h4>
      </div>
      <div id="collapse4" class="panel-collapse collapse in">
        <div class="panel-body"> Nirsal Microfinance Bank (Affiliated with the central bank of Nigeria)</div>
      </div>
      </div>


      
      <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapse5" style={{display:"grid", gridTemplateColumns:"90% 10%"}}>    What is the maximum/minimum AGSMEIS loan amount?  <i className="fa fa-angle-double-down"></i></a>
        </h4>
      </div>
      <div id="collapse5" class="panel-collapse collapse in">
        <div class="panel-body">  3 million naira for start-up, 10 million naira for existing business.</div>
      </div>
      </div>

     


   

        
      <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapse6" style={{display:"grid", gridTemplateColumns:"90% 10%"}}>    How reliable is the process to get the AGSMEIS loan? 
 <i className="fa fa-angle-double-down"></i></a>
        </h4>
      </div>
      <div id="collapse6" class="panel-collapse collapse in">
        <div class="panel-body">  Tested and Trustworthy.</div>
      </div>
      </div>

      <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
     
        <a data-toggle="collapse" data-parent="#accordion" href="#collapse9" style={{display:"grid", gridTemplateColumns:"90% 10%"}}>Is Grant repayable?     <i className="fa fa-angle-double-down"></i></a>
        </h4>
      </div>
      <div id="collapse9" class="panel-collapse collapse in">
        <div class="panel-body">     NO</div>
      </div>
      </div>


     


        </div>
    </div>
  </div>  */}
<FaqsList/>
    </div>
    </div>
    </div>
    
        <Footer />
      </>

   
    </div>
  )
}


// var Index = React.createClass({
//   render: function () {
    // return (
    //   <>
    //     <NavBarsm />
    //     <NavBar />
    //     <div
    //       style={{
    //         margin: "auto",
    //         backgroundImage: `url(${Background})`,
    //       }}
    //     >
    //       <div>
    //       <Collapsible trigger="Start here">
    //     <p>This is the collapsible content. It can be any element or React component you like.</p>
    //     <p>It can even be another Collapsible component. Check out the next section!</p>
    //   </Collapsible>
    //       </div>
    //     </div>
    //     <Footer />
    //   </>
    // );
//   },
// });
// export default Index;
