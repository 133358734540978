import React, { useState, useContext } from "react";
import classess from "./reg.module.css"
import { useHistory } from "react-router-dom";
import UserContext from "../../context/userContext";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";
import bg from "../../../../Images/bg/bg.jpg"


export default function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const { setUserData } = useContext(UserContext);
  const history = useHistory();

  const submit = async (e) => {
    e.preventDefault();
    try {
      const loginUser = { email, password };
      const loginRes = await Axios.post(
        "https://warm-wildwood-97173.herokuapp.com/users/login",
        loginUser
      );
      setUserData({
        token: loginRes.data.token,
        user: loginRes.data.user,
      });
      localStorage.setItem("auth-token", loginRes.data.token);
      history.push("/admin");
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg);
    }
  };
  return (
    <div className="page" style= {{ height:"100vh", background:"white",color:"black",backgroundImge: `url(${bg})`,margin:"auto" }}>

      <h2 style={{width:"80%", margin:"auto", paddingTop:'10px'}}>Log in OrChidDigital Consulting</h2>
      <h3 style={{width:"80%", margin:"auto", paddingTop:'80px'}}>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
      </h3>
      <div style={{width:"80%", margin:"auto"}}>
      <form className="form" onSubmit={submit} style={{width:"80%"}}>
        <label htmlFor="login-email"
        style={{color:"green", fontSize:'15px'}} className="m-1">Email</label>
        <input
        style={{borderTop:'none', borderLeft:"none", borderRight:"none", borderBottom:'1px solid gray', width:'100%', height:'40px'}}
         className={classess.input+ " m-1"}
          id="login-email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />

        <label className="m-1"  style={{color:"green", fontSize:'20px'}} htmlFor="login-password">Password</label>
        <input

         style={{borderTop:'none', borderLeft:"none", borderRight:"none", borderBottom:'1px solid gray', width:'100%', height:'40px'}}
       
         className={classess.input+" m-1"}
        
          id="login-password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <input
         style={{width:"100%"}}
          className="btn btn-dark btn-lg" type="submit" value="Log in" />
      </form>
    </div>
    </div>
  );
}
