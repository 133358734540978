import React, { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../../../src/components/auth/context/userContext";
import { Zoom } from "react-reveal";
import AllAricles from "./AllAricles";
export default function Home() {
  const { userData } = useContext(UserContext);

  return (
    <div className="page">
      {userData.user ? (
        <>
          <AllAricles/>
        </>
      ) : (
        <>
          <Zoom right>
            <Link to="/login">
              <button className="btn btn-dark btn-lg">Log in</button>
            </Link>
          </Zoom>
        </>
      )}
    </div>
  );
}
