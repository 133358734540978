import React from 'react'

export default function Error() {
    return (
        <div className="text-center" style={{marginTop:"40vh"}}>
            <h1>
            404 |PAGE NOT FOUND
            </h1>
        </div>
    )
}
