import React from "react";
import {Link} from "react-router-dom"

import axios from 'axios';

import 'moment/locale/uk'
import ReactTimeAgo from 'react-time-ago'
import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import NavBarsm from "../../Layout/NavBarsm";
import NavBar from "../../Layout/NavBar";
import Footer from "../../Layout/Footer";

import Background from "../../../Images/bg/bg.jpg"
import Blog from  "../../Blog/Blog"
JavascriptTimeAgo.addLocale(en);

const Endpoint ="https://warm-wildwood-97173.herokuapp.com" ||"http://localhost:500"


const ArticlesPosts= props => (
<div>

{/* <Link style={{border:'1px solid blue', padding:"10px", margin:"2px"}}>Delete</Link> */}
<Link to={"/article/"+props.info._id} style={{border:'1px solid blue', padding:"10px", margin:"2px"}}>Delete</Link>
<Blog 
key={props.info._id} 
poster={props.info.poster} 
posted_date={<ReactTimeAgo date={props.info.posted_date} locale="en"/> } 
title={props.info.title} 
post={props.info.post}/>
<br/>

  </div >
)

 class AllAricles extends React.Component {
     

  constructor(props) {
    super(props);
   
    this.state = {
     
      infos: [],
      cut:"",
      loading:true
    };

  }


componentDidMount() {
    axios.get(Endpoint+'/blog/')
        .then(response => {
            this.setState({infos: response.data, loading:false});
        })
        .catch(function (error) {
            console.log(error);
        })

        
}

componentDidUpdate() {
    axios.get(Endpoint+'/blog/')
    .then(response => {
        this.setState({infos: response.data, loading:false});
    })
    .catch(function (error) {
        console.log(error);
    })   
}


ArticlesPostsShow() {
    return this.state.infos.reverse().map(function(currentinfos, i) {
        return <ArticlesPosts info={currentinfos} key={i} />;
    });

}






  render() {
  
    
    let cut = document.querySelectorAll('.cut')
    for(let i=0; i<cut.length; i++){
        cut[i].innerHTML= cut[i].innerHTML.substring(0, 65)+ "..."
      
    }

    return (
    <>
    <NavBarsm/>
    <NavBar/>
        <div style={{ margin:"auto",  backgroundImage: `url(${Background})`, marginBottom:'40px'}}>
        <section className="row" style={{margin:"auto", width:"90vw", paddingTop:"70px"}}>
            <div  style={{margin:"auto", width:"90vw", padding:"5px", textAlign:"center"}}>
            <h1>OUR ARTICLES</h1>
            </div>
            <section className="col-12">

  
            {(this.state.loading)?
   <div style={{ height:"50vh",background:"#f2f2f2"}}>
       
       
       <div style={{paddingTop:'100px', textAlign:"center"}}>Loading...<i className="fa fa-spinner fa-spin"></i></div></div> :
  
            
                    <div> { this.ArticlesPostsShow() }</div>
            }
                   </section>
                   </section>
                   </div>
                   <Footer/>
                   </>
      
    );
  }
}

export default AllAricles



