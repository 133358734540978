import React from "react"
// import styled from "styled-components"

import Faq from "./Faq"
import { StyledFaqsList } from "./faqstyles"

const faqsData = [
  {
    question: "How can I gain access to international and local grant? ",
    answer: "Apply through our website or contact the offices on telephone or in person.",
  },
  {
    question: "What is the time duration to repay back the AGSMEIS loan?",
    answer:<div>It takes about 5years and one year moratorium of 5% per annum, after the first year moratorium its 9% per annum, it is a seven years repayment plan.
    <br/>
    1: Excluding other Loan Applications etc commercial bank, private financial institutions and international and local grants.
    <br/>
    2: while commercial bank require the following - C of O, - Business Plan, - 2 passport photo, -CAC documents etc, incase of asset financing include list of Asset for purchase.<br/>
    3: For commercial bank it takes about 2-3 weeks before disbursal from bank to obiligor account.<br/>
    4: For commercial bank as much as 500million - 1 billion Naira.<br/>
    5: Private financial Institution can also go for 10 million to 50million",
  </div>
  },
  {
    question: "Basic requirements to access the AGSMEIS loan?",
    answer:"Individuals must have a valid Nigeria I.D, 2 passport photograph, must have a registered business name. attend a one week entrepreneurship training with a certificate given at the end of the training."
  },
  {
    question: "How long does it take to get the AGSMEIS loan?  ",
    answer:"Between 6-8weeks."
  },
  {
    question: "Which bank disburses the AGSMEIS loan?   ",
    answer:"Nirsal Microfinance Bank (Affiliated with the central bank of Nigeria)"
  },
  {
    question: " What is the maximum/minimum AGSMEIS loan amount?   ",
    answer:" 3 million naira for start-up, 10 million naira for existing business."
  },
  {
    question: " How reliable is the process to get the AGSMEIS loan?  ",
    answer:"Tested and Trustworthy."
  },
  {
    question: "Is Grant repayable?   ",
    answer:"NO"
  },
  {
    question: "Our Other Services ",
    answer:<div>
<br/>
1.) Asset financing.<br/>
2.) Private and commercial bank facility financing.<br/>
3.) Local purchase order (Overdraft)<br/>
4.) Letter of credit<br/>
5.) Offshore Funding
    </div>
  },
]


const FaqsList = () => {
  return (
    <StyledFaqsList>
      {faqsData.map((faq, i) => (
        <Faq key={"faq_" + i} question={faq.question} answer={faq.answer} />
      ))}
    </StyledFaqsList>
  )
}

export default FaqsList