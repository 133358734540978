import React, { useContext } from "react";
import { Zoom } from "react-reveal";

import {Link} from "react-router-dom"


import UserContext from "../../../../src/components/auth/context/userContext";
import ConsultList from "./consult_list_table"






export default function Home() {
  const { userData } = useContext(UserContext);

  return (
    <div className="page">
      {userData.user ? (
        <>
         <ConsultList/>
    </>
      ) : (
        <>
          {/* <div style={{ height:"100vh", background:"black"}}>
<div style={{paddingTop:"30vh",}}>
<div className="text-center">
   <h1 className="h1 i font-weight-lighter"> You Are Currently Not Logged </h1>
   <br></br> */}
   {/* <Link to="/login"><button className="btn btn-dark btn-lg">Log in</button></Link>  */}
   <Zoom right>
   <Link to="/login"><button className="btn btn-dark btn-lg">Log in</button></Link> 
   </Zoom>
{/* </div>
</div>
</div> */}
        </>
      )}
    </div>
  );
}

