import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import Axios from "axios";
import HomeLog from "../src/components/auth/components/pages/Home";
import Login from "../src/components/auth/components/auth/Login";

import UserContext from "../src/components/auth/context/userContext";
import Index from "../src/components/Home";
import contactus from "../src/components/Contanct";
import services from "../src/components/Services";
import about from "../src/components/About/index";
import consultus from "../src/components/Consultation";
import error from "../src/components/Layout/Error";
import admin from "./components/Admin/Contact_Form_Table/index";
import ConsultFormTable from "./components/Admin/Consultation_Form_Table/ConsultFormTable";
import delete_contact from "./components/Admin/Contact_Form_Table/DeleteContact";
import DeleteConsultList from "./components/Admin/Consultation_Form_Table/DeleteConsultList";
import ApplicationForm from "./components/ApplicationForm/index";
import Confirm from "./components/Layout/Confirm";
import NotSent from "./components/Layout/NotSent";
import applicant_table from "./components/Admin/Application_Form_Table/ApplicationFormTable";
import DeleteApplication from "./components/Admin/Application_Form_Table/Delete_Application";
import EditApplication from "./components/Admin/Application_Form_Table/Edit_Application";
import Blog from "./components/Blog/index";
import Post_Articles from "./components/Admin/Articles/Post_Articles";
import Register from "../src/components/auth/components/auth/Register";
import Articles from "../src/components/Blog/index";
import DeleteArticle from "./components/Admin/Articles/DeleteArtilcles";
import AllArticles from "../src/components/Admin/Articles/AllArticles";
import "../src/components/Layout/float.css"
import Faqs from "../src/components/FAQs/index";
import Privacy from "../src/components/Privacy/index"
const Endpoint =
  "https://warm-wildwood-97173.herokuapp.com" || "http://localhost:500";

function App() {


  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
  });
  // componentDidMount () {
  //   // Include the Crisp code here, without the <script></script> tags
  //   window.$crisp=[];window.CRISP_WEBSITE_ID="f3516ae6-1227-49f0-a95e-2c100659d09d";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
  //   window.$crisp = [];
  //   window.CRISP_WEBSITE_ID = "YOUR_WEBSITE_ID";

  //   (function() {
  //     var d = document;
  //     var s = d.createElement("script");

  //     s.src = "https://client.crisp.chat/l.js";
  //     s.async = 1;
  //     d.getElementsByTagName("head")[0].appendChild(s);
  //   })();
  //  };
  

  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem("auth-token");
      if (token === null) {
        localStorage.setItem("auth-token", "");
        token = "";
      }
      const tokenRes = await Axios.post(
        Endpoint + "/users/tokenIsValid",
        null,
        { headers: { "x-auth-token": token } }
      );
      if (tokenRes.data) {
        const userRes = await Axios.get(Endpoint + "/users/", {
          headers: { "x-auth-token": token },
        });
        setUserData({
          token,
          user: userRes.data,
        });
      }
    };

    checkLoggedIn();
  }, []);

  return (
    <>
      <BrowserRouter>
        <UserContext.Provider value={{ userData, setUserData }}>
   
          <Switch>
            {console.log(
              "Built with love by Samuel Egbajie(Major) https://twitter.com/Engr_Major"
            )}
          

                <Route path="/" exact component={Index} />
            <Route path="/contactus" exact component={contactus} />
            <Route path="/services" exact component={services} />
            <Route path="/about" exact component={about} />
            <Route path="/consultus" exact component={consultus} />
            <Route path="/admin" exact component={admin} />
            <Route path="/success" exact component={Confirm} />
            <Route path="/faqs" exact component={Faqs} />
            <Route path="/privacy" exact component={Privacy} />
            <Route
              path="/consultformtable"
              exact
              component={ConsultFormTable}
            />
            <Route path="/serverdown" exact component={NotSent} />
            <Route
              path="/delete_contact/:id"
              exact
              component={delete_contact}
            />
            <Route
              path="/delete_consult_list/:id"
              exact
              component={DeleteConsultList}
            />
            <Route
              path="/delete_application_list/:id"
              exact
              component={DeleteApplication}
            />
            <Route
              path="/edit_application_list/:id"
              exact
              component={EditApplication}
            />
            <Route path="/apply" exact component={ApplicationForm} />
            <Route path="/applicants_table" exact component={applicant_table} />
            <Route path="/blog" exact component={Blog} />
            <Route path="/post_article" exact component={Post_Articles} />
            <Route path="/articles" exact component={Articles} />
            <Route path="/article/:id" exact component={DeleteArticle} />
            <Route path="/allarticles" exact component={AllArticles} />
            <Route path="/404" component={error} />

            <Route path="/idonotexist" exact component={Register} />

            <Route exact path="/test" component={HomeLog} />
            <Route path="/login" component={Login} />

            <Redirect to="/404" />

          </Switch>
        </UserContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;

//black and white about
//access to finance
//
