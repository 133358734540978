import React from 'react'
import NavBar from "../Layout/NavBar"
import NavBarsm from "../Layout/NavBarsm"
import Footer from "../Layout/Footer"
import Privacy from "./Privacy"
import Contact from "../Layout/contact"
import data from "../../data/data.json"
function index() {
    return (
        <div>
            <NavBarsm/>
            <NavBar/>
            <Privacy/>
            <Contact data={data.Contact}/>
            <Footer/>
        </div>
    )
}

export default index
