import React, { Component } from "react";
import axios from 'axios';
import { Redirect } from 'react-router-dom';


export class Post_Articles extends Component  { 

  constructor(props) {
    super(props);

    this.onChangePost= this.onChangePost.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangePoster = this.onChangePoster.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this)
    
    
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      post: '',
      title: '',
      poster: '',
      image:"",
      isLoading:false,
      base64TextString: "",
      validationError:"",
        isSuccess:false,
        isServerDown:false
     
    }
}
onChangeImage= (e) => {
  // e.preventDefault()
  // console.log("files to upload", e.target.files[0]);
  // let file = e.target.files[0];
 
  // this.setState({
  
  //   file:URL.createObjectURL(e.target.files[0])
  // });
  // if (file) {
  //   const reader = new FileReader();
  //   reader.onload = this.handleload.bind(this);
  //   reader.readAsBinaryString(file);
  // }

  e.preventDefault()
  console.log("files to upload", e.target.files[0]);
  let file = e.target.files[0];
  if(file.size>50000){
    console.log("your image must not be greater than 50kb")
    this.setState({
      validationError:"your Image must not be greater than 50kb"
    })
  }
  else if(file.size<=50000){
    console.log(file)
    this.setState({
      
      file:URL.createObjectURL(e.target.files[0]),
      validationError:""
    });
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleload.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  
 
};
handleload = (readerEvt) => {
  // let binaryString = readerEvt.target.result;
  // this.setState({
  //   image: "data:image/png;base64,"+btoa(binaryString),
  
  // });
  let binaryString = readerEvt.target.result;
    this.setState({
      base64TextString: "data:image/png;base64,"+btoa(binaryString),
    
    });
 
};


onChangePost(e) {
  this.setState({
      post: e.target.value
  });
}

onChangeTitle(e) {
    this.setState({
        title: e.target.value
    });
}

onChangePoster(e) {
    this.setState({
        poster: e.target.value
    });
}




onSubmit(e) {
    e.preventDefault();
    
    console.log(`Form submitted:`);
    
    const newContact = {
    

     post: this.state.post,
      title: this.state.title,
      poster: this.state.poster,
      image:this.state.base64TextString
     
     
       
    }

    axios.post('https://warm-wildwood-97173.herokuapp.com/blog/add', newContact)
        .then(res => { if(res.status===200){
          this.setState({isSuccess:true})
          console.log(res.data)
        }else if(res.status===503){
          this.setState({isServerDown:true})
        }});

    // this.setState({
    //  post: '',
    //   title: '',
    //   poster: '',
      


    // })
    
}
  render(){
    if(this.state.isSuccess){
      return <Redirect to= "/success"/>
    }else if(this.state.isServerDown){
      return <Redirect to="/serverdown"/>
    }
    return (
        <div  style={{ margin: "auto",
         
          
          paddingButtom: "5px"}}>
        <div id="contact" style={{height:"100%"}}>
          <div className="container">
            <div className="col-md-12">
              <div className="row">
                {this.state.isLoading?(<div className="section-title">
               
                <div style={{fontSize:"100px"}}>LOADING...<i  className="fa fa-spinner fa-spin"></i> </div>
               
                </div>):
                <form name="sentMessage" style={{margin:"auto"}} id="contacForm" onSubmit={this.onSubmit} encType="multipart/form-data">
                  
                  <fieldset className="bg-light">
                    <legend className="h1 bg-dark">POST AN ARTICLE</legend>
                    <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                      <input
                            type="file"
                            id="passport"
                            accept=".jpg, .png, .jpeg"
                            // accept="image/png, image/jpg, image/jpeg"
                            // name="applicant_passport"
                            // value={this.state.applicant_passport}
                            onChange={(e) => this.onChangeImage(e)}
                            className="form-control"
                            required="required"
                          />
                           <p className="help-block text-danger">{this.state.validationError}</p>
                              </div>
                       </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          value={this.state.title}
                          
                          onChange={this.onChangeTitle}
                          className="form-control"
                          placeholder="Title"
                         
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          value={this.state.poster}
                          
                          onChange={this.onChangePoster}
                          className="form-control"
                          placeholder="Your Name"
                         
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>


                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                         style={{
                           height:"30vh"
                         }}
                          value={this.state.post}
                          
                          onChange={this.onChangePost}
                          className="form-control"
                          placeholder="PASTE OR TYPE AN ARTICLE HERE"
                         
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
               
  



                  



                   


                    
                    


                






</div>
<br/>
<br/>

</fieldset>








<div className="container">
<div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg bg-blue w-50"style={{border:"1px solid blue"}}>
                    POST ARTICLE
                  </button>


                  </div>
                
                </form>}
              </div>
            </div>
           </div>
              </div>
              </div>
    )
}
}




export default Post_Articles
