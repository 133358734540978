import React, { Component } from "react";
import Forms from "./Forms";

export class Contact extends Component {
  render() {
    return (
      <div >
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p>
                </div>
               <Forms/>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {this.props.data ? this.props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}
                </p>
              </div>
              <div>
                <p>
                Mon-Fri, 08:00AM – 06:00PM
                <br/>
SLOGAN: LIMITLESS POSSIBILITIES

                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" 
                        href= "https://web.facebook.com/Orchiddigitaledc-635826767038728"
                      >
                        <i className="fa fa-facebook"></i>
                      
                      </a>
                    </li>
                    <li>
                     
                         <a  href="https://api.whatsapp.com/send?phone=+2349132129458&text=Hello%21%20%20" target="_blank" rel="noopener noreferrer" >
<i className="fa fa-whatsapp"></i>
</a>
                      
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer"  href="https://twitter.com/Orchiddigitale1">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                   

                    <li>
                      <a target="_blank" rel="noopener noreferrer"  href="https://www.instagram.com/orchiddigitaledc/">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer"  href="https://m.youtube.com/channel/UCs-A6_ceAfUXl7M8OgPw-YQ">
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    );
  }
}

export default Contact;
