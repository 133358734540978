import React, { Component } from 'react'

import HomeScreen from '../Home/HomeScreen';
import NavBar from '../../components/Layout/NavBar';
import NavBarsm from '../../components/Layout/NavBarsm';

import About from '../about';
import Services from '../Home/services';
import Background from "../../Images/twtbg.jpg"
import Testimonials from '../Home/testimonials';

import Contact from '../Layout/contact';
import JsonData  from "../../data/data.json"
import Footer from "../Layout/Footer"
import VideoTestimonies from "../Home/VideoTestimonies/vt"
import "react-loadingmask/dist/react-loadingmask.css";
import Partners from './Partners';
import Un from './Unpartner';

// import Whatsapp from "../Whatsapp"

// import LastThreeArticles from '../Blog/LastThreeArticles';



export default class index extends Component {
    state = {
        landingPageData: {},
      }
      getlandingPageData() {
        this.setState({landingPageData : JsonData})
      }
    
      componentDidMount() {
        this.getlandingPageData();
      }
    
      render() {
        return (
         

          <div>
             
            
               <NavBarsm />
            <NavBar />
                   

            <HomeScreen data={this.state.landingPageData.HomeScreen}/>
          
            <Services data={this.state.landingPageData.Services} />
            <Un />
            <About data={this.state.landingPageData.About} />
         
           
           <Testimonials data={this.state.landingPageData.Testimonials}/>
          {/* <LastThreeArticles/>
          
    */}
    <VideoTestimonies/>
     <Partners/>
     {/* <a class="twitter-timeline" href="https://twitter.com/Orchiddigitale1?ref_src=twsrc%5Etfw">Tweets by Orchiddigitale1</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                
                 */}

                 

<div style={{width:"75vw",marginBotton:"40px",paddingTop:"40px", margin:"auto", backgroundImage: `url(${Background})`, height:"100%", backgroundPosition:"center",backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
  <div style={{paddingBottom:"100px", margin:"auto"}}>
<a style={{background:"#f6f6f6"}}  className="twitter-timeline" data-dnt="true" href="https://twitter.com/Orchiddigitale1" data-widget-id="515125528169160704">TWEETS</a>
    <div id="example1"></div>
</div>
    </div>
  
                <Contact data={this.state.landingPageData.Contact} />

            <Footer/>
         

</div>
        )
      }
    }


    