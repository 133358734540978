
import { Link } from 'react-router-dom';

import logo from "../../Images/logo.svg"
import menu from "../../Images/menu.svg" 

import React, { useState} from 'react'
import classes from './nav.module.css';




export default function NavBar() {
  const [hide, sethide] = useState(true)

  // useEffect(() => {
  //   document.getElementById("navbarNavDropdownddd").style.display=hide?"none":"block";
  //   console.log(sethide)
  // })
 

  return (

      


      <nav onLoad={()=>sethide()}  className="navbar navbar-expand-xl navbar-light  position-fixed container-fluid" style={{zIndex:"1000",height:"80px", marginTop: "0px", background:"white", fontSize:"120px !important"}}>
  <Link to="/" className="navbar-brand" style={{width: "40vw"}} > 
 
       
        <img src={logo}  style={{height:"60px"}}  alt="logo"/>
       
    </Link>
     
  {/* <button onClick={()=>sethide(!hide)} className="navbar-toggler h5" type="button" data-toggle="collapse" data-target="#navbarNavDropdownddd" aria-controls="navbarNavDropdownddd" aria-expanded="true" aria-label="Toggle navigation" >
    <span className="navbar-toggler-icon"></span> */}
   <button className="navbar-toggler" style={{border:'none',  marginLeft:"30vw"}} data-toggle="modal" data-target="#exampleModal">
    {/* <i className="fa fa-bars h3 border-white" ></i> */}
    <img src={menu} alt="menu" style={{width:"25px", height:"25px", border:"green"}}/>
  
   
  </button>
 
  <div  className="navbar-collapse" id="navbarNavDropdownddd" style={{display:hide?"none":"none"}}>
   
   {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  Launch demo modal {/* <span class="iconify" data-inline="false" data-icon="ri:menu-3-fill"></span>
  
</button> */}
   
   <ul className="nav navbar-nav navbar-right h4 text-dark" style={{color:"black !important"}}>
              <li>
                <Link to = "/" className={classes.pagescroll}>
                 Home
                </Link>
              </li>
              <li>
                <Link to = "/about" className={classes.pagescroll}>
                 About
                </Link>
              </li>
              <li>
                <Link to = "/services" className={classes.pagescroll}>
                 Services
                </Link>
              </li>
              

              <li className="nav-item dropdown">
        <Link  to="/#" className={classes.pagescroll+" dropdown-toggle"} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         Contact
        </Link>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
         
          <Link to = "/contactus" className="page-scroll dropdown-item" style={{color:"black", fontSize:"15"}}>
                 Contact Us
                </Link>
                <Link to = "/consultus" className="page-scroll dropdown-item" style={{color:"black",fontSize:"15"}}>
                 Book A Consultation
                </Link>
        </div>
      </li>

      {/* <li className="nav-item dropdown">
        <Link  className={classes.pagescroll+" dropdown-toggle"} href="#" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         Others
        </Link>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
         
          <Link to = "/articles" className="page-scroll dropdown-item" style={{color:"black", fontSize:"15"}}>
                 Articles
                </Link>
                <Link to = "/" className="page-scroll dropdown-item" style={{color:"black",fontSize:"15"}}>
                 FAQs
                </Link>
        </div>
      </li> */}

      
      <li className="nav-item dropdown">
        <Link to="/#"  className={classes.pagescroll+" dropdown-toggle"} href="#" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Others
        </Link>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown1">
         
          <Link to = "/articles" className="page-scroll dropdown-item" style={{color:"black", fontSize:"15"}}>
                Articles
                </Link>
                <Link to = "/faqs" className="page-scroll dropdown-item" style={{color:"black",fontSize:"15"}}>
                 FAQs
                </Link>
        </div>
      </li>
              {/* <li>
                <a href="#team" className="page-scroll">
                  Team
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  Contact
                </a>
              </li> */}
            
      {/* <li className="nav-item dropdown">
        <a  className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Contact Us
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a className="dropdown-item" href="#">Action</a>
          <a className="dropdown-item" href="#">Another action</a>
          <a className="dropdown-item" href="#">Something else here</a>
        </div>
      </li> */}
        

        <li>
        <Link to="/apply"><button className="btn btn-custom btn-lg text-dark p-2" style={{background:"white", color:"blue !important", border:"1px solid blue", margin:"5px"}} >Enroll Today</button></Link>

              </li>
    </ul>

  </div>


 



{/* <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        ...
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      
      </div>
    </div>
  </div>
</div> */}
</nav>
   
  )
}
