import React, { Component } from "react";
import pind from "../../Images/partnersLogo/pind.svg";
import allianz from "../../Images/partnersLogo/allianz.svg";
import ye from "../../Images/partnersLogo/ye.svg";
import rosabon from "../../Images/partnersLogo/rosabon.svg";
import "./swing.css";

export class Partners extends Component {
  render() {
    return (
      <div
        id="team"
        className="text-center "
        style={{ background: "white", color: "black" }}
      >
        <div className="container">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>Meet Our Partners</h2>
            <p></p>
          </div>
          <div id="row">
            <div className="col-md-3 col-sm-6 team " style={{ margin: "auto" }}>
              <div className="thumbnail" style={{border: "5px solid #f5f5f5", borderRadius:"10px"}}>
                {" "}
                <img src={pind} alt="..." className="team-img swing mb-2" />
                {/* <div className="caption">
                  <h3>PIND-Foundation</h3>
                </div> */}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 team " style={{ margin: "auto"}}>
              <div className="thumbnail" style={{border: "5px solid #f5f5f5", borderRadius:"10px"}}>
                {" "}
                <img src={rosabon} alt="..." className="team-img swing mb-2" />
                {/* <div className="caption">
                  <h3>Rosabon Financial Services</h3>
                </div> */}
              </div>
            </div>

            <div className="col-md-3 col-sm-6 team " style={{ margin: "auto"}}>
              <div className="thumbnail" style={{border: "5px solid #f5f5f5", borderRadius:"10px"}}>
                {" "}
                <img src={allianz} alt="..." className="team-img swing mb-2" />
                {/* <div className="caption">
                  <h3>Allianz Insurance Nigeria</h3>
                </div> */}
              </div>
            </div>

            <div className="col-md-3 col-sm-6 team " style={{ margin: "auto" }}>
              <div className="thumbnail " style={{border: "5px solid #f5f5f5", borderRadius:"10px"}}>
                {" "}
                <img
                  src={ye}
                  alt="..."
                  style={{ width: "115px" }}
                  className="team-img swing mb-2"
                />
                <div className="caption" style={{marginTop:"-30px"}}>
                  <h3>Young Entrepreneurs</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Partners;
