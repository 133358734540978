import React, { Component } from "react";
import Buttons from "../Layout/Buttons";
import training from "../../Images/training.jpeg";
import { Fade } from "react-reveal";
import bgg from "../../Images/services.jpg";
import market from "../../Images/market.jpg";
import computer from "../../Images/computer.jpg";
// import computer2 from "../../Images/about.jpeg";
import fm from "../../Images/fm.jpeg";
import finance from "../../Images/finance.jpeg";
import exportm from "../../Images/export.jpeg";
import insurance from "../../Images/insurance.jpeg";
import grant from "../../Images/grants.jpeg";
export class Service extends Component {
  render() {
    return (
      <div id="about" style={{ marginTop: "-100px" }}>
        <div className="container">
          <div className="row">
            <Fade>
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <div id="erp"></div>
                  <h2>
                    ENTERPRISE MANAGEMENT SOFTWARE DEVELOPMENT/MAINTENANCE:
                  </h2>

                  <div className="list-style">
                    <div className="h3 col-xs-12 font-weight-lighter">
                      Enterprise management software development/maintenance is
                      a software that builds and re-engineers solution to cover
                      the needs of large organization.. We work with:
                      <ul>
                        <Fade>
                          <li>Enterprise resource planning (ERP) software,</li>
                          <li>Financial Management, </li>
                          <li>Sales and Marketing, </li>
                          <li>Purchasing, </li>
                          <li>Inventory Management, </li>
                          <li>Client relationship management (CRM) system </li>
                          <li>Contact Management, </li>
                          <li>Reporting,</li>
                          <li>E-mail Tracking,</li>
                          <li>Social Media Management, </li>
                          <li>Enterprise Supply Chain Management Software,</li>
                          <li>Order Management,</li>
                          <li>Return Management,</li>
                          <li>Fore Casting,</li>
                          <li>Inventory Management,</li>
                          <li>Human Resource Management (HRM)App;</li>
                          <li>Online Training,</li>
                          <li>Security,</li>
                          <li>Engagement Management System,</li>
                          <li>Budgeting,</li>
                          <li>Building And Invoicing,</li>
                          <li>Fixed Assets,</li>
                          <li>Inventory Management</li>
                        </Fade>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
            <div className="col-xs-12 col-md-6">
              <img src={computer} className="img-responsive" alt="" />{" "}
           
                <div className="h2 ont-weight-lighter" style={{fontWeight:"lighter"}}>
                <Fade>
                  We intend to meet the needs of an organization that already
                  exists and a system[maybe manual] that exist in a place. the
                  software is developed based on the environment within an
                  organization which is where it is going to be used and where
                  it is going to meet a goal.
                  </Fade>
                </div>
           
              {/* <img src={computer2} className="img-responsive" alt="" /> */}


              <div className="row">
        
            </div>

            </div>
            <ul></ul>
          </div>

          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div id="export_raw_materials" style={{ height: "100px" }}></div>
              <div className="about-text">
                <h2> EXPORT OF RAW MATERIALS:</h2>
                <div className="list-style">
                  <div className=" col-xs-12 h2 font-weight-lighter pb-5">
                    <img src={exportm} alt="export" style={{width:"100%"}}/>
                    <Fade>
                      {" "}
                      We move goods and services from the country to other
                      countries to be purchased.
                      <br /> We offer a complete range of marketing and
                      technical services designed to expedite the exportation of
                      food and agricultural products. We ensure smooth sailing
                      through foreign exports.
                      <br />
                      {/* We help the candidates to improve their entrepreneurial
                      skills to better assess the business environment acquire
                      basic knowledge for developing and using marketing
                      plan,develop skills for better business planning in order
                      to ensure that they are better prepared to start their own
                      business. */}
                    </Fade>
                  </div>{" "}
                </div>
                <br />
                <br />
                <br />{" "}
               
              </div>
            </div>

            <div className="col-xs-12 col-md-6">
              <div id="fac_management" style={{ height: "100px" }}></div>
              <div className="about-text">
                <h2> FACILITY MANAGEMENT:</h2>

                <div className="list-style">
                  <div className=" col-xs-12 h2 font-weight-lighter">
                    <Fade>
                      <img
                        src={fm}
                        alt="fm"
                        style={{ maxHeight: "70%", maxWidth: "100%" }}
                      />
                      We co-ordinate everything that keeps a business; physical
                      buildings, systems and assets running.
                      <br />
                      We cover a diverse range of services and expertise. We
                      have resources to create skilled teams of engineers,
                      facility managers project managers to help your property
                      and infrastructure assets achieve best values.
                    </Fade>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-xs-12 col-md-6">
          <div id="entrepreneurship" style={{ height: "100px" }}></div>
              <div className="about-text">
                <h2 className="pt-5">ENTREPRENUERSHIP TRAINING: </h2>
<p>
<img src={training} className="img-responsive" alt="" />
</p>
                <div className="list-style">
                  <div className=" col-xs-12 h2 font-weight-lighter">
                    <Fade>
                      {" "}
                      It is a training scheme to develop persons for self
                      employment or for organizing, financing and managing new
                      ventures or businesses.
                      <br />
                      We help the candidates to improve their entrepreneurial
                      skills to better assess the business environment acquire
                      basic knowledge for developing and using marketing
                      plan,develop skills for better business planning in order
                      to ensure that they are better prepared to start their own
                      business.
                    </Fade>
                    <p>
                      <Buttons />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 list-style">
            <div> 
                  <div id="access_private" ></div>
                  <h2>ACCESS TO PRIVATE /INTERNATIONAL AND LOCAL GRANTS:</h2>
                  <br/>
                  <img
                        src={grant}
                        alt="fm"
                        style={{ maxHeight: "70%", maxWidth: "100%" }}
                      />
                      <br/>
                  <div className="col-xs-12 h2 font-weight-lighter">
                    {/* We help you access government grant which are tools to
                    address entrepreneurship start-up and existing business on
                    the boarder spectrum and like everything else, they are used
                    to service grow /expand the economy of one self, ventures/
                    enterprise. We help you to private funding opportunities
                    that have contributed to the lion share of financial
                    assistance and infrastructural support. */}
                    We help you access government grant which are tools to
                    address entrepreneurship start-up and existing business on
                    the boarder spectrum and like everything else, they are used
                    to  grow service and expand the economy of one self, ventures
                    (enterprise). 
                    We give you access to private funding opportunities that have contributed to the lion share of financial assistance and infrastructural support.
                    <br/>
                    <br/>

                    <div id="tax_audit" style={{ height: "70px" }}></div>
                <h2> INSURANCE /AUDIT AND TAX CLEARANCE:</h2>

                <div className="list-style">
                  <h3 className=" col-xs-12 h3 font-weight-lighter">
                  <img
                        src={insurance}
                        alt="fm"
                        style={{ maxHeight: "70%", maxWidth: "100%" }}
                      />
<br/>
                    <Fade>
                      {" "}
                      We provide farmers with financial protection against
                      production hindrance caused by natural persils such as
                      diseases, frost, wind, wildlife, excessive moisture, hail,
                      and other natural disasters.
                    </Fade>{" "}
                    <br/>
                    <div className="about-text">
                      Business services We render alongsite:
                    <div className="h3 col-xs-12 font-weight-lighter">
                    <ul>
                      <li> CAC Business Name Registration</li>
<li> Business Plan </li>
<li> Credit Facilitation </li>
<li> Business Services Development</li>
<li >Mentoring & Monitoring:<div className="m-4"> The mentoring  opportunity is aimed at leveraging expertise of successful entrepreneurs and professionals to leverage their practical know-how</div></li>

                    </ul>
                    </div>
                    </div>
                  </h3>
                </div>
                  </div>
                </div>
             
            </div>
            </div>
          <div className="row">
            {/* <div className="col-xs-12 col-md-6">
             
            </div> */}

          
           
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <div id="agro_consulting" style={{ height: "100px" }}></div>
                <h2>AGRO CONSULTING: </h2>
                <img
                  src={bgg}
                  className="img-responsive"
                  height="100%"
                  alt=""
                />
                <div className="list-style">
                  <div className=" col-xs-12 h2 font-weight-lighter">
                    <Fade>
                      {" "}
                      {/* We specialize in advising, provide technical, commercial
                      and financial advice and information to farming,
                      agricultural and public sector.
                      <br />
                      We render services agricultural services to farmers and
                      potential farmers on how they can leverage on the modern
                      methods, cost minimization and production growth. To have
                      a profitable agro-investment at the end of the day. */}
                      We provide agricultural services ranging from technical, commercial and finance to farmers and potential farmers, educating them on how to leverage on the modern methods, cost minimization and production growth to achieve a profitable agro-investment.
                      <br/>
                       We are
                      responsible for writing and developing material content,
                      directing and managing multiple projects, clients liaison,
                      business development and ensuring projects are delivered
                      on time and to budget.
                    </Fade>
                   
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6">
              {/* <div style={{ height: "80px" }}></div> */}
              <div className="pt-5">
              <div id="access_to_market" style={{ height: "100px" }}></div>
              <div className="about-text">
                <h2> ACCESS TO MARKET:</h2>
                <img src={market} className="img-responsive" alt="" />
                <div className="list-style">
                  <div className=" col-xs-12 h2 font-weight-lighter">
                    <Fade>
                      We connect bulk purchasers for your products and services,
                      we sell your goods and services across borders and enter
                      foreign markets.
                      <br />
                      We give access to bulk buyers/off takers, it could be
                      domestic or international trade.{" "}
                    </Fade>{" "}
                  </div>
                </div>
              </div>
                 <p>
                      <Buttons />
                    </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <div id="access_to_finance" style={{ height: "100px" }}></div>
                

                <div className="list-style">
                  <div className=" col-xs-12 h2 font-weight-lighter">
                    <img src={finance} alt="finance" style={{width:"100%"}}/>
                   
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6">
              <div className="about-text">
              
              <div  style={{ height: "100px" }}></div>
                <div className=" col-xs-12 h2 font-weight-lighter">
                <h2>ACCESS TO FINANCE:</h2>
                <Fade>
                      {" "}
                      
                      We provide financial services through commercial banks
                      private financial institution, for start ups and expanding
                      business dedicated to support both on-off farm
                      agricultural activities and businesses.
                      <br /> We provide operational technical and management
                      support including MSME banking and financial institutions,
                      private investors, development finance, institution and
                      government active in financial sector development in the
                      <br/>
                      We provide Access-To-Finance through linkages to various funding sources like CBN Intervention Funds, Agricultural Credit Guarantee Scheme and Micro Finance Bank Lending. We provide information on types and purpose of Funds relevant to Stage of venture development. Our engagement also includes support for participants to register with various stake-holders groups or institutions such as MAN, Traders Associations, Credit Bureau institutions etc. to access funds Monitor all successful participants to ensure appropriate utilization of funds for the purpose granted. Assisting Funds Providers to ensure repayments in line with stipulated terms of credit administration.
                      
                    </Fade>{" "}
                </div>
                {/* <div id="tax_audit" style={{ height: "150px" }}></div>
                <h2> INSURANCE /AUDIT AND TAX CLEARANCE:</h2>

                <div className="list-style">
                  <div className=" col-xs-12 h2 font-weight-lighter">
                  <img
                        src={insurance}
                        alt="fm"
                        style={{ maxHeight: "70%", maxWidth: "100%" }}
                      />
<br/>
                    <Fade>
                      {" "}
                      We provide farmers with financial protection against
                      production posses caused by natural persils such as
                      diseases, frost, wind, wildlife, excessive moisture, hail,
                      and other naturaal disasters.
                    </Fade>{" "}
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-xs-12 col-md-6">
              <div id="access_to_market" style={{ height: "100px" }}></div>
              <div className="about-text">
                <h2> ACCESS TO MARKET:</h2>
                <img src={market} className="img-responsive" alt="" />
                <div className="list-style">
                  <div className=" col-xs-12 h2 font-weight-lighter">
                    <Fade>
                      We connect bulk purchasers for your products and services,
                      we sell your goods and services across borders and enter
                      foreign markets.
                      <br />
                      We give access to bulk buyers/off takers, it could be
                      domestic or international trade.{" "}
                    </Fade>{" "}
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-xs-12 col-md-6">
              <img src={market} className="img-responsive" alt="" />
            </div> */}
          </div>

         
        </div>
      </div>
    );
  }
}

export default Service;
