import React, { Component } from "react";
import { Zoom } from "react-reveal";

export class Mission extends Component {
  render() {
    return (
      <div
        id="team"
        className="text-center"
        style={{ background: "gray", color: "white" }}
      >
        <div className="container" style={{ margin: "auto" }}>
          <div className="col-md-12 section-title text-center ">
            <h2 style={{ fontSize: "30px", fontWeight: "lighter" }}>
              MISSION AND VISION
            </h2>
          </div>
          <div id="row">
            <Zoom>
              <div className="col-lg-6  col-md-12 col-sm-12 ">
                <div className="thumbnail">
                  {" "}
                  <h3   className="text-left"  style={{ fontSize: "22px", fontWeight: "lighter" }}>
                    Our Mission
                  </h3>
                  <div className="caption text-white">
                    <h4
                      className="text-white text-left"
                      style={{ fontSize: "20px", fontWeight: "lighter" }}
                    >
                      {" "}
                      To take Nigeria farm produce to the next level of
                      globalization - To secure financial stability in Nigeria
                      through entrepreneurship, to promote the production of
                      affordable nutritious, safe and accessible foods and
                      agricultural products ensuring sustainable management of
                      natural resources enhancing market access for the entire
                      agricultural sector, contributions towards reduction of
                      poverty and hunger, to maintain top organizations.
                    </h4>
                  </div>
                </div>
              </div>
            </Zoom>
            <Zoom>
              <div className="col-lg-6 col-md-12 col-sm-12 ">
                <div className="thumbnail">
                  {" "}
                  <h3
                   className="text-left" style={{ fontSize: "22px", fontWeight: "lighter" }}>
                    Our Vision
                  </h3>
                  <div className="caption">
                    <h4
                      className="text-white text-left"
                      style={{
                        fontSize: "20px",
                        fontWeight: "lighter",
                        textTransform: "lowercase",
                      }}
                    >
                      {" "}
                      WITH OUR NETWORK OF PARTNERS WE FACILITE ACCESS TO GOVERNMENT
                      FINANCE AND MARKET TO HELP BUILD VISIBLE, SUSTAINABLE AND
                      LEADING ENTERPRISE THAT CREATE VALUE FOR ALL STAKEHOLDER
                      IN A SOCIAL RESPONSIBLE AND ENVIROMENTAL FRIENDLY MANNER
                    </h4>
                  </div>
                </div>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
    );
  }
}

export default Mission;
