import React from "react";
import ReadMoreReact from 'read-more-react';
export default function Blog(props) {
  return (
  
    <div style={{margin:"10px"}}>
   
    <div id="articles" style={{background:"white",borderRadius:"10px",  marginBottom: "30px", border:"1px solid  lightgrey", paddingBottom:"40px", paddingTop:"20px" }}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12" style={{ margin: "auto" }}>
            <div className="about-text">
              <h1 style={{ fontSize: "25px", textTransform: "capitalize" , fontWeight:"bolder",color:"gray", borderBottom:"1px solid gray"}}>
                {props.title}
               
              </h1>
             
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "light",
               
                  color: "gray",
                }}
              >
                 <ReadMoreReact 
                 title
                min={100}
                ideal={200}
                max={300}
                text={props.post}
              readMoreText={<button>read more</button>}/>
              
            
              </div>

              <br />
              <br />
              <div className="container h3">
                <div>Article by: {props.poster}</div>
                <div
                  id="date"
                  className="date"
                  style={{ color: "green", fontSize: "14px" }}
                >
                  {props.posted_date}
                </div>
            
              </div>
            </div>
          </div>

        
        </div>
      </div>
    </div>
 </div>
 
  );
}
