import React,{ useContext } from "react";
import UserContext from "../auth/context/userContext";
import PropTypes from "prop-types";
import MaterialTitlePanel from "./material_title_panel";
import styles from "./Styles"


const SidebarContent = props => {
  const { userData, setUserData } = useContext(UserContext);
  const style = props.style
    ? { ...styles.sidebar, ...props.style }
    : styles.sidebar;

  const links = [];
  const logout = () => {
    setUserData({
      token: undefined,
      user: undefined,
    });
    localStorage.setItem("auth-token", "");
  };
  
//   for (let ind = 0; ind < 10; ind++) {
//     links.push(
//       <a key={ind} href="#" style={styles.sidebarLink}>
//         Mock menu item {ind}
//       </a>
//     );
//   }

  return (
    <MaterialTitlePanel title={"Welcome " + userData.user.displayName} style={style}>
      <div style={styles.content}>
        <br/>
        <br/>
        <a href="/" style={styles.sidebarLink}>
          Home
        </a>

      
        <div style={styles.divider} />
        <a href="/admin" style={styles.sidebarLink}>
         Contact List Table
        </a>

        <a href="/consultformtable" style={styles.sidebarLink}>
         Consultation List Table
        </a>

        <a href="/applicants_table" style={styles.sidebarLink}>
       Registration List Table
        </a>
        <a href="/post_article" style={styles.sidebarLink}>
         Post Articles
        </a>

        <a href="/allarticles" style={styles.sidebarLink}>
       Articles List
        </a>
  
        <button className="btn btn-dark text-white" onClick={logout}>Log out</button>
        
        {links}
      </div>
    </MaterialTitlePanel>
  );
};

SidebarContent.propTypes = {
  style: PropTypes.object
};

export default SidebarContent;