import React from 'react'
import classes from "./privacy.module.css"
function Privacy() {
    return (
        <section style={{background:"#efefef", paddingTop:"100px", paddingBottom:"100px"}}>
        <div  className="container">
            <h1 className={classes.h1}>Privacy Policy</h1>
<p className={classes.p}>Last updated November, 2020.</p>


<br/><br/>
<h2 className={classes.h2}>INTRODUCTION</h2>
<p className={classes.p}>
ORCHIDDIGITAL CONSULTING LIMITED we respect the privacy of our users. This  Privacy Policy explains how we collect, use, disclose, and safeguard your information  when you visit our website [www.orchiddigitalconsulting.com] including any other media  form, social media handles and application related or connected thereto (collectively,  from Orchid digital). Please read this privacy policy carefully. If you do not agree with  the terms of this privacy policy, please do not access the site.  <br/>
We reserve the right to make changes to this Privacy Policy at any time and for any  reason. We will alert you about any changes by updating the “Last Updated” date of  this Privacy Policy. Any changes or modifications will be effective immediately upon  posting the updated Privacy Policy on the Site, and you waive the right to receive  specific notice of each such change or modification. <br/> 
You are encouraged to periodically review this Privacy Policy to stay informed of  updates. You will be deemed to have been made aware of, will be subject to, and will be  deemed to have accepted the changes in any revised Privacy Policy by your continued  use of the Site after the date such revised Privacy Policy is posted.  <br/>
This privacy policy was created using Termly. <br/>

</p>





<h2 className={classes.h2}>COLLECTION OF YOUR INFORMATION </h2>
<p className={classes.p}>We may collect information about you in a variety of ways. The information we may  collect on the Site includes:</p>
<br/>
<p className={classes.p}>
<b className={classes.b}>Personal Data </b><br/>
Personally identifiable information, such as your name, email address, and telephone  number, and demographic information, such as your age, gender, hometown, and  interests, that you voluntarily give to us [when you register with the Site] when you  choose to participate in various activities related to the Site [and our mobile application],  such as online chat and message boards. You are under no obligation to provide us  with personal information of any kind, however your refusal to do so may prevent you  from using certain features of the Site . 
</p>
<p className={classes.p}>
<b className={classes.b}>Derivative Data </b> <br/>
Information our servers automatically collect when you access the Site, such as your IP  address, your browser type, your operating system, and your access times. [If you are  using our mobile application, this information may also include your device name and  type, your operating system, your phone number, your country, your likes and replies to  a post, and other interactions with the application and other users via server log files, as  well as any other information you choose to provide.] 

</p>
<p className={classes.p}>
<b className={classes.b}>Facebook Permissions  </b> <br/>
The Site may by default access your Facebook basic account information, including  your name, email, gender, birthday, current city, and profile picture URL, as well as  other information that you choose to make public. We may also request access to other  permissions related to your account, such as friends, check ins, and likes, and you may  choose to grant or deny us access to each individual permission. For more information  regarding Facebook permissions, refer to the Facebook Permissions Reference page. 
</p>
<p className={classes.p}>
<b className={classes.b}>Data From Social Networks   </b> <br/>
User information from social networking sites, such as [Facebook, Google+, Instagram,  Pinterest, Twitter], including your name, your social network username, location,  gender, birth date, email address, profile picture, and public data for contacts, if you  connect your account to such social networks.
</p>


<p className={classes.p}>
<b className={classes.b}>Mobile Device Data    </b> <br/>
Device information, such as your mobile device ID, model, and manufacturer, and  information about the location of your device, if you access the Site from a mobile  device. 
</p>


<p className={classes.p}>
<b className={classes.b}>Third-Party Data    </b> <br/>
Information from third parties, such as personal information or network friends, if you  connect your account to the third party and grant the Site permission to access this  information. </p>


      <p className={classes.p}>
<b className={classes.b}>Data From Contests, Giveaways, and Surveys  
   </b> <br/>
Personal and other information you may provide when entering contests or giveaways  and/or responding to survey </p>
       

       <h2 className={classes.h2}>
       USE OF YOUR INFORMATION 
       </h2>
       <br/>
       <p className={classes.p}>
       Having accurate information about you permits us to provide you with a smooth,  efficient, and customized experience. Specifically, we may use information collected  about you via the Site to:  

       </p>
       <br/>
       <p className={classes.p}>
       ● Administer promotions, and contests. <br/>
       ● Compile anonymous statistical data and analysis for use internally or with third  parties.  
<br/>
● Create and manage your account. <br/>
● Deliver targeted advertising, coupons, newsletters, and other information  regarding promotions and the Site to you.  <br/>
● Email you regarding your account or application. <br/>
● Fulfill and manage applications and other transactions related to the Site. <br/>
● Generate a personal profile about you to make future visits to the Site [and our  mobile application] more personalized. <br/>
● Increase the efficiency and operation of the Site [and our mobile application].<br/>
● Monitor and analyze usage and trends to improve your experience with the Site  [and our mobile application]. <br/>
● Notify you of updates to the Site. <br/>
● Offer new products, services, [mobile applications,] and/or recommendations to  you. <br/>
● Perform other business activities as needed. <br/>
● Prevent fraudulent transactions, monitor against theft, and protect against  criminal activity. <br/>
● Request feedback and contact you about your use of the Site [and our mobile  application] .  <br/>
● Resolve disputes and troubleshoot problems. <br/>
● Respond to product and customer service requests. <br/>
● Send you a newsletter. <br/>
● Solicit support for the Site. <br/>
● [Other] <br/>

       </p>
       <h2 className={classes.h2}>
       DISCLOSURE OF YOUR INFORMATION 
       </h2>
       <br/>
       <p className={classes.p}>
       We may share information we have collected about you in certain situations. Your  information may be disclosed as follows: 
       </p>

       <p className={classes.p}>
           
 <b className={classes.b}>By Law or to Protect Rights </b>   <br/>    
If we believe the release of information about you is necessary to respond to legal  process, to investigate or remedy potential violations of our policies, or to protect the  rights, property, and safety of others, we may share your information as permitted or  required by any applicable law, rule, or regulation. This includes exchanging  information with other entities for fraud protection and credit risk reduction. 
Third-Party Service Providers  <br/>
We may share your information with third parties that perform services for us or on our  behalf, including, data analysis, email delivery, hosting services, customer service, and 
marketing assistance. <br/>
<b className={classes.b}>Marketing Communications </b><br/>
With your consent, or with an opportunity for you to withdraw consent, we may share  your information with third parties for marketing purposes, as permitted by law. 
<br/><b className={classes.b}>Interactions with Other Users </b> <br/>
If you interact with other our social media pages, those users may see your name,  profile photo, and descriptions of your activity, including sending invitations to other  users, chatting with other users, liking posts, following blogs.  
<br/>

<b className={classes.b}>Online Postings </b><br/>
When you post comments, contributions or other content to the Site [or our mobile  applications], your posts may be viewed by all users and may be publicly distributed  outside the Site [and our mobile application] in perpetuity.  
<br/>
<b className={classes.b}>Third-Party Advertisers   </b><br/>
We may use third-party advertising companies to serve ads when you visit the Site [or  our mobile application]. These companies may use information about your visits to the  Site [and our mobile application] and other websites that are contained in web cookies  in order to provide advertisements about goods and services of interest to you.  
<br/>
<b className={classes.b}>Affiliates    </b><br/>
We may share your information with our affiliates, in which case we will require those  affiliates to honor this Privacy Policy. Affiliates include our parent company and any  subsidiaries, joint venture partners or other companies that we control or that are under  common control with us. 

<br/>
<b className={classes.b}>Business Partners     </b><br/>
We may share your information with our business partners to offer you certain products,  services or promotions. 
<br/>
    <b className={classes.b}>Social Media Contacts     </b><br/>
If you connect to the Site [or our mobile application] through a social network, your  contacts on the social network will see your name, profile photo, and descriptions of  your activity.]  
<br/>
<b className={classes.b}>Other Third Parties     </b><br/>
We may share your information with advertisers and investors for the purpose of  conducting general business analysis. We may also share your information with such  third parties for marketing purposes, as permitted by law.  
<br/>
<b className={classes.b}>Sale or Bankruptcy      </b><br/>
If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired  by another entity, we may transfer your information to the successor entity. If we go out  of business or enter bankruptcy, your information would be an asset transferred or  acquired by a third party. You acknowledge that such transfers may occur and that the  transferee may decline honor commitments we made in this Privacy Policy. 
We are not responsible for the actions of third parties with whom you share personal or  sensitive data, and we have no authority to manage or control third-party solicitations. If  you no longer wish to receive correspondence, emails or other communications from  third parties, you are responsible for contacting the third party directly. 

       </p>
       <br/><h2 className={classes.h2}>
       TRACKING TECHNOLOGIES 
       </h2>
       <br/>
       <p className={classes.p}>
           <b className={classes.b}>Cookies and Web Beacons </b>
<br/>


           Orchiddigital Consulting LImited may use cookies, web beacons, tracking pixels, and  other tracking technologies on the Site [and our mobile application] to help customize  the Site [and our mobile application] and improve your experience. When you access  the Site [or our mobile application], your personal information is not collected through  the use of tracking technology. Most browsers are set to accept cookies by default. 

You can remove or reject cookies, but be aware that such action could affect the  availability and functionality of the Site [or our mobile application]. You may not decline  web beacons. However, they can be rendered ineffective by declining all cookies or by  modifying your web browser’s settings to notify you each time a cookie is tendered,  permitting you to accept or decline cookies on an individual basis.] For more information  on how we use cookies, please refer to our Cookie Policy posted on the Site, which is  incorporated into this Privacy Policy. By using the Site, you agree to be bound by our  Cookie Policy.] 
<br/><b className={classes.b}>Internet-Based Advertising </b><br/>
Additionally, we may use third-party software to serve ads on the Site [and our mobile  application], implement email marketing campaigns, and manage other interactive  marketing initiatives. This third-party software may use cookies or similar tracking  technology to help manage and optimize your online experience with us. For more  information about opting-out of interest-based ads, visit the Network Advertising  Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool. 

<br/><b className={classes.b}>Website Analytics  </b><br/>
We may also partner with selected third-party vendors[, such as [Adobe Analytics,]  [Clicktale,] [Clicky,] [Cloudfare,] [Crazy Egg,] [Flurry Analytics,] [Google Analytics,]  [Heap Analytics,] [Inspectlet,] [Kissmetrics,] [Mixpanel,] [Piwik,] and others], to allow  tracking technologies and remarketing services on the Site [and our mobile application]  through the use of first party cookies and third-party cookies, to, among other things,  analyze and track users’ use of the Site [and our mobile application] , determine the  popularity of certain content and better understand online activity. By accessing the  Site[,our mobile application,], you consent to the collection and use of your information  by these third-party vendors. You are encouraged to review their privacy policy and  contact them directly for responses to your questions. We do not transfer personal  information to these third-party vendors. However, if you do not want any information to  be collected and used by tracking technologies, you can visit the third-party vendor or  the Network Advertising Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out
Tool. <br/>
You should be aware that getting a new computer, installing a new browser, upgrading  an existing browser, or erasing or otherwise altering your browser’s cookies files may  also clear certain opt-out cookies, plug-ins, or settings. 


</p>

<h2 className={classes.h2}>THIRD-PARTY WEBSITES </h2>
<p className={classes.p}>
The Site may contain links to third-party websites and applications of interest, including  advertisements and external services, that are not affiliated with us. Once you have  used these links to leave the Site [or our mobile application], any information you  provide to these third parties is not covered by this Privacy Policy, and we cannot  guarantee the safety and privacy of your information. Before visiting and providing any  information to any third-party websites, you should inform yourself of the privacy policies  and practices (if any) of the third party responsible for that website, and should take  those steps necessary to, in your discretion, protect the privacy of your information. We  are not responsible for the content or privacy and security practices and policies of any  third parties, including other sites, services or applications that may be linked to or from  the Site [or our mobile application]. 
</p>


<h2 className={classes.h2}>SECURITY OF YOUR INFORMATION </h2>
<p className={classes.p}>
We use administrative, technical, and physical security measures to help protect your  personal information. While we have taken reasonable steps to secure the personal  information you provide to us, please be aware that despite our efforts, no security  measures are perfect or impenetrable, and no method of data transmission can be  guaranteed against any interception or other type of misuse. Any information disclosed  online is vulnerable to interception and misuse by unauthorized parties. Therefore, we  cannot guarantee complete security if you provide personal information. 
</p>
<h2 className={classes.h2}>POLICY FOR CHILDREN </h2>
<p className={classes.p}>
We do not knowingly solicit information from or market to children under the age of 13. If  you become aware of any data we have collected from children under age 13, please  contact us using the contact information provided below.  
</p>
<h2 className={classes.h2}>CONTROLS FOR DO-NOT-TRACK FEATURES  </h2>
<p className={classes.p}>
Most web browsers and some mobile operating systems include a Do-Not-Track  (“DNT”) feature or setting you can activate to signal your privacy preference not to have  data about your online browsing activities monitored and collected. No uniform  technology standard for recognizing and implementing DNT signals has been finalized.  As such, we do not currently respond to DNT browser signals or any other mechanism  that automatically communicates your choice not to be tracked online. If a standard for  online tracking is adopted that we must follow in the future, we will inform you about that  practice in a revised version of this Privacy Policy.If you set the DNT signal on your  browser, we will respond to such DNT browser signals.  
</p>



<br/>
<h2 className={classes.h2}>OPTIONS REGARDING YOUR INFORMATION   </h2>
<p className={classes.p}>
<b className={classes.b}>Account Information </b><br/>
You may at any time review or change the information in your account or terminate your  account by:<br/> 
● Logging into your account settings and updating your account <br/>
● Contacting us using the contact information provided or fill out our form attached.<br/>
 <br/>
Upon your request to terminate your account, we will deactivate or delete your account  and information from our active databases. However, some information may be retained  in our files to prevent fraud, troubleshoot problems, assist with any investigations,  enforce our Terms of Use and/or comply with legal requirements.] 
<br/>
<b className={classes.b}>Emails and Communications</b><br/>
If you no longer wish to receive correspondence, emails, or other communications from  us, you may opt-out by: <br/>
<br/>
● Noting your preferences at the time you register your account with the Site [or  our mobile application] 
<br/>
● Logging into your account settings and updating your preferences. 
<br/>● Contacting us using the contact information provided. 
<br/>If you no longer wish to receive correspondence, emails, or other communications from  third parties, you are responsible for contacting the third party directly.  
</p>
<h2 className={classes.h2}>CONTACT US</h2> 
<br/>
<p className={classes.p}>
If you have questions or comments about this Privacy Policy, please contact us below: 
</p>
{/* [Company Name] 
[Street Address] 
[City, State] 
[Phone Number] 
[Social Media Handles] 
[Email].  */}

        </div>
        </section>
    )
}

export default Privacy
