import React from "react";
import about from "../../Images/about.jpeg";
import { Fade } from "react-reveal";
export default function About() {
  return (
    <div id="about" style={{ background: "gray" }}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src={about} className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div >
              <h1 style={{ fontSize: "35px" }} className="text-dark">
                About Us
              </h1>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "lighter",
                  color: "white",
                }}
                className="h2"
              >
                Orchiddigital Consulting Limited has been in operation, invented
                measures to eradicate poverty, halt the increment of
                unemployment, promote job creation through starts-up of new
                business and also alleviate business failure by helping existing
                businesses grow. Orchiddigital Consulting Limited is the part of
                the strategy to hand-hold entrepreneurs through a tried and
                tested education process resulting in stronger sustainable
                business growth.
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 text-white mt-3 pb-5">
            <h1 style={{ fontSize: "25px" }} className="text-dark">
              OUR ENGAGEMENT APPROACH
            </h1>

            <div
              className="h3 u"
              style={{ fontSize: "20px", fontWeight: "lighter" }}
            >
              Specifically, Orchiddigital facilitates the achievement of the
              following outcomes:
            </div>
            <div className="about-text">
              <ul
                className="list-style h2"
                style={{ fontSize: "20px", fontWeight: "lighter" }}
              >
                <Fade>
                  <li>
                    Develop entrepreneurial skills of individuals to
                    successfully start, manage, diversify and expand business
                    enterprises
                  </li>
                  <br />

                  <li>
                    {" "}
                    Facilitate easy access to Start-up Capital for successful
                    participants, especially funds from commercial banks,
                    microfinance banks, allied financial institutions,
                    Non-Governmental Organizations and other development
                    agencies.
                  </li>
                  <br />
                  <li>
                    Generate employment opportunities through entrepreneurship,
                    for Nigerians in-line with the agenda of the Federal
                    Government and the goals of National Economic Empowerment
                    and Development Strategy (NEEDS).
                  </li>
                  <br />
                  <li>
                    Support certified participants with strong entrepreneurship
                    ecosystem that helps them realize their aspirations of
                    starting or expanding their enterprises.
                  </li>
                </Fade>
              </ul>
            </div>
          </div>

          <div
            className="col-xs-12 col-md-6  text-white mt-3 pb- "
            style={{ background: "gray" }}
          >
            <h1 style={{ fontSize: "25px" }} className="text-dark">
              ACCOMPLISHMENT PLANS
            </h1>
            <div
              className="h2"
              style={{ fontSize: "20px", fontWeight: "lighter" }}
            >
              <Fade>
                To accomplish this mandate, participants are put through our one
                week long entrepreneurship development program, where we operate
                online and physical classroom training engagement model. We make
                the ‘Learning by Doing’ maxim in our development approach, and
                even our training follows the experiential learning methodology.
                Orchiddigital Consulting Limited engage aspiring and existing
                entrepreneurs, regardless of level of education, gender or
                socio-economic status. Only criteria is for participants to be
                18yrs & above.
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
