import React, { Component } from 'react'
import workshop from "../Images/workshop.svg"
import {Zoom} from "react-reveal"
export class about extends Component {
  render() {
    return (
        <div id="about" style={{background:"#e5e5e5"}}>
        <div className="container">
          <Zoom>
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src={workshop} className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
               
                <p style={{fontWeight:"lighter"}}>{this.props.data ? this.props.data.paragraph : 'loading...'}</p>
                <h3>Why Choose Us?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12 h5">
                    <ul>
                      {this.props.data ? this.props.data.Why.map((d, i) => <li style={{fontWeight:"lighter", fontSize:"15px"}}  key={`${d}-${i}`}>{d}</li>) : 'loading'}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12 h5">
                    <ul>
                    {this.props.data ? this.props.data.Why2.map((d, i) => <li style={{fontWeight:"lighter", fontSize:"15px"}}  key={`${d}-${i}`}> {d}</li>) : 'loading'}

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Zoom>
        </div>
      </div>
    )
  }
}

export default about
