import React from 'react'
import NavBarsm from './NavBarsm'
import NavBar from './NavBar'
import Footer from './Footer'
import Msg from './Msg'

export default function Confirm() {
    return (
        <>
        <NavBarsm/>
        <NavBar/>
        <Msg/>
        <Footer/>
        </>
    )
}
