import React from "react";
import Sidebar from "react-sidebar";
import MaterialTitlePanel from "../material_title_panel";
import SidebarContent from "../SiddebarContent";
import axios from 'axios';
import Moment from 'react-moment';
import 'moment/locale/uk'
import { Link } from "react-router-dom";
const Endpoint ="https://warm-wildwood-97173.herokuapp.com" ||"http://localhost:500"
const styles = {
  contentHeaderMenuLink: {
    textDecoration: "none",
    color: "white",
    padding: 8
  },
  content: {
    padding: "16px"
  }
};

const mql = window.matchMedia(`(min-width: 1500px)`);


const ConsultForm= props => (
    <tr>

        <td style={{fontSize:"10px", minWidth:"200px"}}><Moment locale="uk" parse="YYYY-MM-DD HH:mm">{props.profile.posted_date}</Moment></td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.form_id}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.gender}</td>
        <td style={{fontSize:"10px" , minWidth:"200px"}}>{props.profile.name}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>0{props.profile.phone_number}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.email}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.resident_address}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.resident_lga}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.resident_state}</td>
     

         <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.resident_country}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.marital_status}</td>
        <td style={{fontSize:"10px" , minWidth:"200px"}}>{props.profile.age_range}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.education_level} <div>{props.profile.other_education_level}</div></td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.graduation_year}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.spoken_language}<div>{props.profile.other_spoken_language}</div></td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.mobile_phone_use}</td>
     

     
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.computer_use}<div>{props.profile.other_software_use}</div></td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.present_employment}<div>{props.profile.specify_employment}</div></td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.working_experience}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.own_bank_account}<div>{props.profile.own_business_account}</div></td>
     

        <td style={{fontSize:"10px", minWidth:"200px"}}>
        <div>{props.profile.branch}</div>
        <div>{props.profile.atm}</div>
        <div>{props.profile.pos}</div>
        <div>{props.profile.internet}</div>
        <div>{props.profile.mobile_app}</div>
        
        </td>

           
        <td style={{fontSize:"10px", minWidth:"200px"}}><div>{props.profile.state_of_origin}</div></td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.business_req_number}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.busines_registered_as}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.business_status}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.business_net_worth}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}>{props.profile.reffered_by}</td>
        <td style={{fontSize:"10px", minWidth:"200px"}}><a href={props.profile.applicant_passport} download><img src={props.profile.applicant_passport} style={{width:"160px"}} alt={props.profile.name}/></a></td>

       
{/*      
        <td><img src={props.profile.applicant_passport} alt=""/></td> */}
        <td style={{fontSize:"10px", minWidth:"200px"}}>     <Link to={"/delete_application_list/"+props.profile._id}>Delete</Link> 
        
          {/* <Link to={"/edit_application_list/"+props.profile._id}>Edit</Link> */}
        
        </td>

     
     
     
      {/* <td style={{fontSize:"10px"}}>{props.info.posted_date}</td>
        <td style={{fontWeight:"bolder", fontSize:"10px"}}>{props.info.contact_name}</td>
        <td style={{fontSize:"10px"}}>{props.profile.contact_number}</td>
        <td style={{fontSize:"10px"}}>{props.info.contact_email}</td>
        <td style={{fontSize:"10px"}}>{props.info.contact_message}</td>
        
        <td>
           
            <Link to={"/delete_consult_list/"+props.info._id}>Delete</Link>

        </td> */}



    </tr>
)

 class  ApplicationList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      docked: mql.matches,
      open: false,
      profiles: []
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);
  }


componentDidMount() {
    axios.get(Endpoint+'/apply/')
        .then(response => {
            this.setState({profiles: response.data});
        })
        .catch(function (error) {
            console.log(error);
        })
}

componentDidUpdate() {
    axios.get(Endpoint+'/apply/')
    .then(response => {
        this.setState({profiles: response.data});
    })
    .catch(function (error) {
        console.log(error);
    })   
}

contactFormList() {
    return this.state.profiles.map(function(currentinfos, i) {
        return <ConsultForm profile={currentinfos} key={i} />;
    });
}




  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetOpen(open) {
    this.setState({ open });
  }

  mediaQueryChanged() {
    this.setState({
      docked: mql.matches,
      open: false
    });
  }

  toggleOpen(ev) {
    this.setState({ open: !this.state.open });

    if (ev) {
      ev.preventDefault();
    }
  }

  render() {
    const sidebar = <SidebarContent />;

    const contentHeader = (
      <span>
        {!this.state.docked && (
          <Link
            onClick={this.toggleOpen}
            // href="#"
            style={styles.contentHeaderMenuLink}
          >
            =
          </Link>
        )}
      \
        <span>OrchidDigital Admin Section</span>
      </span>
    );

    const sidebarProps = {
      sidebar,
      docked: this.state.docked,
      open: this.state.open,
      onSetOpen: this.onSetOpen
    };

    return (
      <Sidebar {...sidebarProps}>
        <MaterialTitlePanel title={contentHeader}>
          <div style={styles.content}>
          <div>
                <h3>Contact  List</h3>
                <table className="table table-striped" style={{ marginTop: 20, overflowX:"auto", width:"100%" }}>
                    <thead style={{background:"blue", color:"white"}}>
                        <tr>
                        <th style={{fontSize:"12px"}}>Application Date</th>
                        <th style={{fontSize:"12px"}}>Form ID</th>
                        <th style={{fontSize:"12px"}}>Gender</th>
       
                        <th style={{fontSize:"12px"}}>Name</th>
                        <th style={{fontSize:"12px"}}>Phone Number</th>
                        <th style={{fontSize:"12px"}}>Email Address</th>
       
                        <th style={{fontSize:"12px"}}>Residence Address</th>
                        <th style={{fontSize:"12px"}}> Residence LGA</th>
                        <th style={{fontSize:"12px"}}>Residence State</th>

<th style={{fontSize:"12px"}}>Residence Country</th>

                        <th style={{fontSize:"12px"}}>Marital Status</th>
                        <th style={{fontSize:"12px"}}>Date of Birth</th>
                        <th style={{fontSize:"12px"}}>Education Qualification</th>
       

                        <th style={{fontSize:"12px"}}>Graduation Year</th>
                        <th style={{fontSize:"12px"}}> English Profficiency</th>
                        <th style={{fontSize:"12px"}}>Use of phone</th>
                        <th style={{fontSize:"12px"}}>Computer Usage</th>
  
                        <th style={{fontSize:"12px"}}>Employment Status</th>
       

                        <th style={{fontSize:"12px"}}>Work Experience Years</th>
                        <th style={{fontSize:"12px"}}>Bank Account</th>
                        <th style={{fontSize:"12px"}}> Use of Banking Channel</th>
                        <th style={{fontSize:"12px"}}>State of Origin</th>
                        <th style={{fontSize:"12px"}}>CAC Number</th>
                        <th style={{fontSize:"12px"}}> Registered as</th>
                        <th style={{fontSize:"12px"}}>Business Status</th>
                        <th style={{fontSize:"12px"}}> Business Networth</th>
                        <th style={{fontSize:"12px"}}>Reffered By:</th>
                        <th style={{fontSize:"12px"}}>PASSPORT</th>
                      
                        
                        
                        <th style={{fontSize:"12px"}}> ACTIONS</th>
       

                        
       
                        </tr>
                    </thead>
                    <tbody>
                        { this.contactFormList().reverse() }
                    </tbody>
                </table>
                </div>
          </div>
         
        </MaterialTitlePanel>
      </Sidebar>
    );
  }
}

export default ApplicationList 
