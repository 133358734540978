import React from "react";
import axios from "axios";
import "moment/locale/uk";
// import ReactTimeAgo from "react-time-ago";
import Moment from 'react-moment';
import 'moment-timezone';
// import JavascriptTimeAgo from "javascript-time-ago";
// import en from "javascript-time-ago/locale/en";
import NavBarsm from "../Layout/NavBarsm";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
// import Background from "../../Images/bg/bg.jpg";
import Blog from "./BlogContainer";
// JavascriptTimeAgo.addLocale(en);

const Endpoint =
  "https://warm-wildwood-97173.herokuapp.com";

const ArticlesPosts = (props) => (
  <Blog
    key={props.info._id}
    id={props.info._id}
    poster={props.info.poster}
    posted_date={<div><Moment fromNow ago locale="en">{props.info.posted_date}</Moment> Ago</div>}
    title={props.info.title}
    post={props.info.post}
    image={props.info.image}
  />
);

class index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      infos: [],
      cut: "",
      loading: true,
    };
  }

  componentDidMount() {
    axios
      .get(Endpoint + "/blog/")
      .then((response) => {
        this.setState({ infos: response.data, loading: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // componentDidUpdate() {
  //   axios
  //     .get(Endpoint + "/blog/")
  //     .then((response) => {
  //       this.setState({ infos: response.data, loading: false });
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  ArticlesPostsShow() {
    return this.state.infos.map(function (currentinfos, i) {
      return <ArticlesPosts info={currentinfos} key={i} />;
    });
  }

  render() {
   

    return (
      <>
        <NavBarsm />
        <NavBar />
        <div
          style={{
            margin: "auto",
            // backgroundImage: `url(${Background})`,
      background:"#f2f2f2"
          }}
        >
          <section
            className="row"
            style={{ margin: "auto", width: "97vw", paddingTop: "100px" }}
          >
            <div
              style={{
                margin: "auto",
                width: "90vw",
                padding: "25",
                textAlign: "left",
               
                
              }}
            >
              <h1 style={{fontWeight:"800", fontSize:"26px"}}>OUR ARTICLES <i className="fa fa-book"></i></h1>
            </div>
            <section className="col-12" > 
              {this.state.loading ? (
                <div style={{ height: "50vh", background: "#ffffff" }}>
                  <div style={{ paddingTop: "100px", textAlign: "center", }}>
                    Loading...<i className="fa fa-spinner fa-spin"></i>
                  </div>
                </div>
              ) : (
                <div > {this.ArticlesPostsShow().reverse()}</div>
              )}
            </section>
          </section>
        </div>
        <Footer />
      </>
    );
  }
}

export default index;
