import React from "react";
import Background from "../../Images/bg/bg.jpg"
export default function FirstScreen() {
  return (
    <div className="text-center pt-5">
      <div className="pt-5">
        <h1
          style={{
            paddingTop: "60px",
            fontSize: "50px",
            fontWeight: "900",
            paddingBottom: "60px",
            backgroundImage: `url(${Background})`,
         
          }}
        >
          SERVICES
        </h1>
      </div>
    </div>
  );
}
