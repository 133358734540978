import React, { useContext } from "react";
import ApplicationList from "./application_list";
import { Link } from "react-router-dom";
import UserContext from "../../../../src/components/auth/context/userContext";
import { Zoom } from "react-reveal";
export default function Home() {
  const { userData } = useContext(UserContext);

  return (
    <div className="page">
      {userData.user ? (
        <>
          <ApplicationList />
        </>
      ) : (
        <>
          <Zoom right>
            <Link to="/login">
              <button className="btn btn-dark btn-lg">Log in</button>
            </Link>
          </Zoom>
        </>
      )}
    </div>
  );
}
