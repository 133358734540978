 
 const styles = {
    sidebar: {
      width: 230,
      height: "100%",
      background:"black"
    },
    sidebarLink: {
      display: "block",
      padding: "16px 0px",
     color:"gray",
      textDecoration: "none",
      fontSize:"12px",
     
    },
    "sidebarLink:hover":{
     backgroundColor:"blue",
     fontSize:"32px",
      color: "white",
      
    },
    divider: {
      margin: "2px 0",
      height: 1,
      backgroundColor: "#757575"
    },
    content: {
      padding: "16px",
      height: "100%",
      backgroundColor: "white",
      width:"100%"
    }
  };
  

  export default styles